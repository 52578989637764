// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';  
import './Navbar.css';

function Navbar() {
    return (
        <header className="navbar">
            <div className="logo">Daily Grids</div>
            <nav>
                <Link to="/">Home</Link> 
                <Link to="/contact-us">Contact Us</Link> 
                <button className="btn-docs" onClick={() => window.location.href = '/report-problem'}> 
                    Report a Problem
                </button>
            </nav>
        </header>
    );
}

export default Navbar;
