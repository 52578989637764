// src/components/IntroSection.js
import React from 'react';
import './IntroSection.css';

function IntroSection() {
    return (
        <section className="intro">
            <h1>Achieve More Every Day with Daily Grid</h1>
            <p>Life is busy, and managing tasks, projects, and goals can be challenging. DailyGrids is a versatile planner designed to help you organize your schedule, track productivity, and focus on what matters most. With adaptable features for every lifestyle, DailyGrids keeps you on top of your day, every day.</p>
                <div className="buttons">
                <button className="btn-primary">Download App</button>
                <button className="btn-secondary" onClick={() => window.location.href = 'https://app.dailygrids.com/'} >Continue to Web App</button>
            </div>
        </section>
    );
}

export default IntroSection;
