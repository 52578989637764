import React from 'react';
import './Previewimg.css';
import Image from './assets/img1.png';

function Previewimg() {
    return (
        <header className="previewimg">
            <div className="previewimage">
                <img src={Image} alt="Preview" />
            </div>
        </header>
    );
}

export default Previewimg;
