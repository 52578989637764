// src/App.js
import React from 'react';
import IntroSection from './IntroSection';
import Previewimg from './Previewimg';
import Features from './Features';


import './HomePage.css';

function HomePage() {
  return (
            <div className="App">
                <IntroSection />
                <Previewimg />
                <Features />
            </div>
     )}

export default HomePage;
