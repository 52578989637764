import React, { useState } from 'react';
import './ReportProblem.css';

const ReportProblem = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const [statusMessage, setStatusMessage] = useState('');

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Sending form data to the backend using fetch API
      const response = await fetch('https://studyplanner1.onrender.com/api/report-problem', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setStatusMessage('Problem reported successfully!');
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        }); 
      } else {
        setStatusMessage(data.error || 'Failed to report the problem.');
      }
    } catch (error) {
      setStatusMessage('An error occurred while submitting the report.');
      console.error('Error:', error);
    }
  };

  return (
    <div className="report-problem-container">
      <h2>Report a Problem</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Your name"
          required
        />

        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Your email address"
          required
        />

        <label htmlFor="subject">Subject</label>
        <input
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          placeholder="Subject"
          required
        />

        <label htmlFor="message">Describe your Issue</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Provide a detailed description of the issue"
          required
        ></textarea>

        <button type="submit">Report Issue</button>
      </form>

      {/* Display status message */}
      {statusMessage && <p className="status-message">{statusMessage}</p>}
    </div>
  );
};

export default ReportProblem;
