import React from 'react';
import './Features.css';

function BenefitsSection() {
  return (
    <section className="benefits-section">
      <h2 className="benefits-heading">Why Choose Daily Grids?</h2>
      <p className="benefits-intro">
        With DailyGrids, you’re not just managing tasks – you’re creating a more organized, focused, and balanced life. Here’s why users love it:
      </p>
      <div className="benefits-list">
        <div className="benefit-item">
          <h3>Versatile & Easy to Use</h3>
          <p>Designed to adapt to anyone’s schedule and goals, from students to executives.</p>
        </div>
        <div className="benefit-item">
          <h3>Personalized Insights</h3>
          <p>Get actionable insights to help you work smarter and meet your goals faster.</p>
        </div>
        <div className="benefit-item">
          <h3>Stay Accountable</h3>
          <p>Track your progress toward both short-term and long-term goals, and get reminders to keep moving forward.</p>
        </div>
        <div className="benefit-item">
          <h3>All Your Tools in One Place</h3>
          <p>From notes to timers, everything you need for an organized life is here.</p>
        </div>
      </div>
    </section>
  );
}

export default BenefitsSection;
